

#navbarSupportedContent{
    flex-direction: column!important;
}

#navbarSupportedContent .breadcrumb{
    border-width: medium!important;
}




amplify-sign-out amplify-button button .button {
    padding: 0.8rem;
    min-width: 2.0rem;
    background-color: var(--amplify-tertiary-color);
}